import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { path } from '../../../environments/path';
import { Observable } from 'rxjs';
import { optionsDniAuthResponse, optionsResponse } from '../../utils/http.utils';
import { getPaymentSession } from '../../utils/localstorage.util';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    private urlBase: string = environment.url_service;
    private sessionToken: string;
    private sessionDni: string;

    constructor(private http: HttpClient) {
    }

    public checkToken(): Promise<any> {
        const options = optionsDniAuthResponse(this.dni, this.token);
        return this.http.get<any>(this.urlBase + path.ENDPOINT_TOKEN, options).toPromise();
    }

    public verifyCaptcha(token: string): Observable<HttpResponse<any>> {
        const data = { recaptcha_response: token };
        return this.http.post(this.urlBase + path.ENDPOINT_RECAPTCHA, data, optionsResponse());
    }

    get token(): string {
        this.sessionToken = this.sessionToken || getPaymentSession().token || null;
        return this.sessionToken;
    }

    set token(value: string) {
        this.sessionToken = value;
    }

    get dni(): string {
        this.sessionDni = this.sessionDni || getPaymentSession().dni || null;
        return this.sessionDni;
    }

    set dni(value: string) {
        this.sessionDni = value;
    }
}
