'use strict';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { MessageError } from 'src/app/core/interfaces/message-error.inteface';
import { OrderService } from 'src/app/services/apis/order.service';
import { Router } from '@angular/router';
import { SessionService } from '../../services/apis/session.service';
import { dniValidator } from 'src/app/core/validators/dni.validator';
import { TagManagerService } from 'src/app/services/tag-manager.service';
import { cleanPaymentSession } from '../../utils/localstorage.util';

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
    showDelay: 0,
    hideDelay: 0,
    touchendHideDelay: 0,
};

@Component({
    selector: 'app-pago-servicios',
    templateUrl: './pago-servicios.component.html',
    styleUrls: ['./pago-servicios.component.scss']
})
export class PagoServiciosComponent implements OnInit {

    public enabledBtn: boolean;
    public formRescatarData: FormGroup;
    public invalidCounter = 0;
    public invalidCaptcha = false;
    public validForm: boolean;
    public spinner = false;

    public messageToast = {
        attempsLimit: 'Ha superado la cantidad de intentos, recargue la página',
        accessCodeInvalid: 'El codigo de acceso es invalido',
        noPendingPayments: 'No posee ordenes pendientes'
    };

    public messageError: MessageError = {
        txtRut: [
            { type: 'required', message: 'El rut es obligatorio' },
            { type: 'dniLength', message: 'El rut es muy corto' },
            { type: 'dniFormat', message: 'El rut tiene un formato incorrecto' },
            { type: 'dniInvalid', message: 'El rut es invalido' }
        ],
        accessCode: [
            { type: 'required', message: 'El código de acceso es obligatorio' },
            { type: 'codeInvalid', message: 'El código de acceso es invalido' },
        ]
    };

    constructor(
        private router: Router,
        private toastr: ToastrService,
        public fb: FormBuilder,
        private recaptchaV3Service: ReCaptchaV3Service,
        private sessionService: SessionService,
        private orderService: OrderService,
        private tag: TagManagerService
    ) {
        this.validForm = true;
        this.formRescatarData = this.createFormularioRescatarData();
        cleanPaymentSession();
    }

    createFormularioRescatarData() {
        return this.fb.group({
            txtRut: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(12), dniValidator]],
            accessCode: ['', [Validators.required]]
        });
    }

    ngOnInit() {
        this.enabledBtn = false;
        this.tag.init();
    }

    validateForm() {
        if (this.formRescatarData.valid) {
            this.spinner = true;
            this.doRecaptcha();
        } else {
            this.tag.error(this.tag.message.dniInvalid);
            this.validForm = false;
            this.invalidCounter++;
        }
    }

    getPayments(tokenGoogle: string) {
        const formValue = this.formRescatarData.value;
        const rut = formValue.txtRut.replace(/\./gi, '').replace('-', '');
        const clientSecureCode = formValue.accessCode;

        this.orderService
            .getPayableOrders(rut.toUpperCase(), tokenGoogle, clientSecureCode)
            .subscribe(({data, token}: any) => {
                this.spinner = false;
                if (data.length > 0) {
                    const route = data.length > 1 ? '/servicios' : `/resumen/${ data[0].orderCode }`;
                    this.sessionService.token = token;
                    this.sessionService.dni = rut.toUpperCase();
                    this.router.navigate([route], { state: { payments: data } });
                } else {
                    this.toastr.info(this.messageToast.noPendingPayments);
                }
            }, (err) => {
                this.spinner = false;
                this.toastr.error(err.error.error.message);
                this.tag.error(this.tag.message.clientNotRegister);
                this.formRescatarData.controls.accessCode.setErrors({ codeInvalid: true });
                this.invalidCounter++;
            });
    }

    doRecaptcha() {
        this.recaptchaV3Service.execute('submit').subscribe((token) => {
            this.sessionService.verifyCaptcha(token).subscribe((response) => {
                if (response.status === 200) {
                    if (this.invalidCounter < 3) {
                        this.getPayments(token);
                    } else {
                        this.invalidCaptcha = true;
                        this.toastr.error(this.messageToast.attempsLimit);
                    }
                }
                this.spinner = false;
            }, (err) => {
                if (err.status === 500) {
                    this.invalidCaptcha = true;
                    this.toastr.error(this.messageToast.attempsLimit);
                }
                this.spinner = false;
                this.validForm = false;
            });
        });
    }

}
