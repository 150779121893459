import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrderPayment } from '../../core/interfaces/order.payment.interface';

@Component({
  selector: 'app-panel-services',
  templateUrl: './panel-services.component.html',
  styleUrls: ['./panel-services.component.scss']
})
export class PanelServicesComponent implements OnInit {

  @Input() order: OrderPayment;

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  goToResumen(order: OrderPayment) {
    this.router.navigate(['/resumen', order.orderCode], { state: { order } });
  }
}
