'use strict';

export const APP_STATUS = {
    PRODUCT_ACTIVE: 'PRODUCT_ACTIVE',
    PRODUCT_DISABLED: 'PRODUCT_DISABLED',
    PRODUCT_TYPE_ACTIVE: 'PRODUCT_TYPE_ACTIVE',
    PRODUCT_TYPE_DISABLED: 'PRODUCT_TYPE_DISABLED',
    PRODUCT_SUBTYPE_ACTIVE: 'PRODUCT_SUBTYPE_ACTIVE',
    PRODUCT_SUBTYPE_DISABLED: 'PRODUCT_SUBTYPE_DISABLED',
    PRODUCT_CATEGORY_ACTIVE: 'PRODUCT_CATEGORY_ACTIVE',
    PRODUCT_CATEGORY_DISABLED: 'PRODUCT_CATEGORY_DISABLED',
    PRODUCT_SUBCATEGORY_ACTIVE: 'PRODUCT_SUBCATEGORY_ACTIVE',
    PRODUCT_SUBCATEGORY_DISABLED: 'PRODUCT_SUBCATEGORY_DISABLED',
    ORDER_CREATED: 'ORDER_CREATED',
    ORDER_PENDING: 'ORDER_PENDING',
    ORDER_PAID: 'ORDER_PAID',
    ORDER_CANCELED: 'ORDER_CANCELED',
    ORDER_REFUNDED: 'ORDER_REFUNDED',
    ORDER_PAYMENT_ATTEMPT: 'ORDER_PAYMENT_ATTEMPT',
    ORDER_REFUND_REQUESTED: 'ORDER_REFUND_REQUESTED',
    USER_ENABLED: 'USER_ENABLED',
    USER_DISABLED: 'USER_DISABLED',
    COMMERCE_ENABLED: 'COMMERCE_ENABLED',
    COMMERCE_DISABLED: 'COMMERCE_DISABLED'
};

export const PAYABLE_ORDER_STATUS = [APP_STATUS.ORDER_CREATED, APP_STATUS.ORDER_PAYMENT_ATTEMPT];

export abstract class AppConstants {
    public static IVA = 1.19;
}

export const CURRENCIES = {
    UF: 'CLF',
    CLP: 'CLP'
};

export const WIX_PRODUCTS_ID = [1];
