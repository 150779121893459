'use strict';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { path } from '../../../environments/path';
import { Observable } from 'rxjs';
import { HttpModel } from '../../core/models/http.model';
import { Client } from '../../core/interfaces/client.interface';
import { optionsResponse, optionsParamsResponse } from '../../utils/http.utils';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {

    private httpModel: HttpModel = new HttpModel();
    private urlBase: string = environment.url_service + path.ENDPOINT_WEBPAY;

    constructor(private http: HttpClient) {
    }

    public executeWebpayPayment(client: Client, orderCode: string): Observable<HttpResponse<any>> {
        return this.http.post(this.urlBase + '/' + orderCode, client, optionsResponse());
    }

    public confirmWebpayPayment(orderCode: string, webpayToken: string): Observable<HttpResponse<any>> {
        const queryParams = new HttpParams().append('webpayToken', webpayToken);
        return this.http.put(this.urlBase + '/' + orderCode, {}, optionsParamsResponse(queryParams));
    }
}
