export const KEYS = {
    SESSION_TOKEN: 'session_token',
    SESSION_DNI: 'session_dni',
};

export const getPaymentSession = (): { token: string; dni: string; } => ({
    token: localStorage.getItem(KEYS.SESSION_TOKEN),
    dni: localStorage.getItem(KEYS.SESSION_DNI)
});

export const savePaymentSession = (token, dni) => {
    localStorage.setItem(KEYS.SESSION_TOKEN, token);
    localStorage.setItem(KEYS.SESSION_DNI, dni);
};

export const cleanPaymentSession = () => {
    localStorage.removeItem(KEYS.SESSION_TOKEN);
    localStorage.removeItem(KEYS.SESSION_DNI);
};

