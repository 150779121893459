import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormatAmountPipe } from './pipes/format-amount.pipe';
import { DniDirective } from './directives/dni.directive';
import { FormatDniPipe } from './pipes/format-dni.pipe';
import { FormatPhonePipe } from './pipes/format-phone.pipe';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';

@NgModule({
    imports: [
        BrowserModule
    ],
    declarations: [
        FormatAmountPipe,
        DniDirective,
        FormatDniPipe,
        FormatPhonePipe,
        NumbersOnlyDirective,
        CustomCurrencyPipe,
    ],
    exports: [
        FormatAmountPipe,
        FormatDniPipe,
        DniDirective,
        FormatPhonePipe,
        NumbersOnlyDirective,
        CustomCurrencyPipe
    ]
})
export class CoreModule {}
