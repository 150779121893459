'use strict';

import { HttpHeaders } from '@angular/common/http';
import { Client } from '../interfaces/client.interface';

export class HttpModel {

    constructor() {
    }
}
