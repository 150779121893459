import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '../../services/apis/session.service';


/**
 * Valida si al acceder a las rutas se tiene un token valido
 * Si no lo tiene o es inválido, redirecciona a la ruta "/pagar"
 */
@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    private isAuthorized = false;

    constructor(private router: Router,
                private sessionService: SessionService) {
    }

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        if (this.sessionService.token === null) {
            this.isAuthorized = false;
            await this.router.navigate(['/pagar']);
        } else {
            try {
                await this.sessionService.checkToken();
                this.isAuthorized = true;
            } catch (e) {
                await this.router.navigate(['/pagar']);
            }
        }
        return this.isAuthorized;
    }
}
