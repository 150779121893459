'use strict';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Navigation, Params, Router } from '@angular/router';
import { FormatAmountPipe } from 'src/app/core/pipes/format-amount.pipe';
import { PaymentService } from '@services/apis/payment.service';
import { WebpayBoucher } from '../../core/interfaces/webpay.boucher.interface';
import { TagManagerService } from 'src/app/services/tag-manager.service';
import { cleanPaymentSession } from '../../utils/localstorage.util';
import {WIX_PRODUCTS_ID} from "../../core/constants/app.constants";

@Component({
    selector: 'app-exito-confirmacion',
    templateUrl: './exito-confirmacion.component.html',
    styleUrls: ['./exito-confirmacion.component.scss'],
    providers: [FormatAmountPipe]
})
export class ExitoConfirmacionComponent implements OnInit {

    webpayTransactionStatus: string;
    orderCode: string;
    webpayToken: string;
    boucher: WebpayBoucher;
    amountPaid: number;
    stateExtra: Navigation;
    WEBPAY_STATUS = {
        TRANSACTION_TO_CONFIRM: 'Transacción correcta, pendiente confirmar webpay',
        TRANSACTION_ERR: 'Transacción con problemas',
        TRANSACTION_PAID: 'Transacción confirmada',
        TRANSACTION_NOT_FOUND: 'No existe transacción'
    };

    constructor(
        private activatedRoute: ActivatedRoute,
        private paymentService: PaymentService,
        private tag: TagManagerService,
        private router: Router) {
        this.stateExtra = this.router.getCurrentNavigation();
    }

    ngOnInit() {
        this.orderCode = this.activatedRoute.snapshot.paramMap.get('orden');
        this.evaluateWebpayQueryParams(this.stateExtra);
        switch (this.webpayTransactionStatus) {
            case this.WEBPAY_STATUS.TRANSACTION_TO_CONFIRM:
                this.confirmTransactionAndDisplay();
                break;
            default:
                this.router.navigate(['/resumen', this.orderCode], { state: { error: true } });
                break;
        }
    }

    private evaluateWebpayQueryParams(extraNavigation: Navigation) {
        const WP_KEY = {
            TOKEN_OK: 'token_ws',
            TOKEN_ERR: 'TBK_TOKEN',
            SESSION: 'TBK_ID_SESION',
            ORDEN: 'TBK_ORDEN_COMPRA'
        };
        const queryParams = extraNavigation.finalUrl.queryParams;
        const transactionOK = queryParams[WP_KEY.TOKEN_OK] && this.WEBPAY_STATUS.TRANSACTION_TO_CONFIRM;
        const transactionNOK = queryParams[WP_KEY.TOKEN_ERR] && queryParams[WP_KEY.SESSION] &&
            queryParams[WP_KEY.ORDEN] && this.WEBPAY_STATUS.TRANSACTION_ERR;

        this.webpayTransactionStatus = transactionOK ? this.WEBPAY_STATUS.TRANSACTION_TO_CONFIRM :
            (transactionNOK ? this.WEBPAY_STATUS.TRANSACTION_ERR : this.WEBPAY_STATUS.TRANSACTION_NOT_FOUND);
        this.webpayToken = this.webpayTransactionStatus === this.WEBPAY_STATUS.TRANSACTION_TO_CONFIRM ?
            queryParams[WP_KEY.TOKEN_OK] : '';
    }

    private confirmTransactionAndDisplay() {
        this.paymentService.confirmWebpayPayment(this.orderCode, this.webpayToken).subscribe(response => {
            cleanPaymentSession();
            const webpayBoucher: WebpayBoucher = response.body && response.body.data;

            if (response.status === 200 && webpayBoucher && webpayBoucher.webpayResponse.details[0].status === 'AUTHORIZED') {
                this.webpayTransactionStatus = this.WEBPAY_STATUS.TRANSACTION_PAID;
                this.boucher = webpayBoucher;

                this.getAmountPaid(this.boucher);
                this.tag.success(this.boucher);
            } else {
                this.webpayTransactionStatus = this.WEBPAY_STATUS.TRANSACTION_ERR;
                this.redirectToResume();
            }
        }, error => {
            this.webpayTransactionStatus = this.WEBPAY_STATUS.TRANSACTION_ERR;
            this.redirectToResume();
        });
    }

    private getAmountPaid(boucher: WebpayBoucher): void {
        this.amountPaid = boucher.webpayResponse.details.reduce((a, b) => a + b.amount, 0);
    }

    private redirectToResume() {
        this.router.navigate(['/resumen', this.orderCode], { state: { error: true } });
    }

    getNumber(value: string) {
        return Number(value);
    }

    get isWixProduct(): boolean {
        return this.boucher
            && WIX_PRODUCTS_ID.includes(this.boucher.productId)
            && this.boucher.productDescription.toLowerCase().includes('wix');
    }
}
