'use strict';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatAmount'
})
export class FormatAmountPipe implements PipeTransform {

  transform(value: any): string {
    const valueInitial = (value && value.toString()) || '';
    const amount = valueInitial.split('').reverse().join('').replace(/(?=d*\.?)(\d{3})/g, '$1.');
    const amountWithPoint = amount.split('').reverse().join('').replace(/^[\.]/, '');
    const amountFormat = '$' + amountWithPoint;
    return amountFormat;
  }

}
