import { NgModule } from '@angular/core';
import { LoadingComponent } from './loading/loading.component';

@NgModule({
    imports: [],
    declarations: [
        LoadingComponent
    ],
    exports: [
        LoadingComponent
    ],
    entryComponents: [
        LoadingComponent
    ]
})
export class SharedModule {}
