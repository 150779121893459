import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContainerPrincipalComponent } from './container-principal/container-principal.component';
import { PanelServicesComponent } from './panel-services/panel-services.component';
import { CoreModule } from '../core/core.module';
import { StepsWixComponent } from './steps-wix/steps-wix.component';



@NgModule({
  declarations: [ContainerPrincipalComponent, PanelServicesComponent, StepsWixComponent],
  imports: [
    CommonModule,
    CoreModule
  ],
  exports: [
    ContainerPrincipalComponent,
    PanelServicesComponent,
    StepsWixComponent
  ]
})
export class ComponentsModule { }
