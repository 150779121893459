'use strict';

import { HttpHeaders, HttpParams } from '@angular/common/http';


/* Headers */
export const HEADER_JSON_PUBLIC = () => new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Channel', 'PUBLIC');

export const HEADER_DNI_AUTH = (dni, token) => HEADER_JSON_PUBLIC()
    .append('Dni', dni)
    .append('Authorization', `Bearer ${ token }`);

export const HEADER_ACCESSCODE = (clientAccessCode: string) => HEADER_JSON_PUBLIC()
    .append('Client-Access-Code', clientAccessCode);


/* Observe */
export const OBSERVE_RESPONSE = () => 'response' as 'response';


/* Options */
export const optionsResponse = () => ({
    headers: HEADER_JSON_PUBLIC(),
    observe: OBSERVE_RESPONSE()
});

export const optionsParamsResponse = (httpParams: HttpParams) => ({
    headers: HEADER_JSON_PUBLIC(),
    observe: OBSERVE_RESPONSE(),
    params: httpParams
});

export const optionsDniAuthResponse = (dni, token) => ({
    headers: HEADER_DNI_AUTH(dni, token),
    observe: OBSERVE_RESPONSE(),
});

export const optionsAccessCode = (accessCode: string) => ({
    headers: HEADER_ACCESSCODE(accessCode)
});

export const optionsParamIdList = (idList: string[]) => ({
    params: new HttpParams().append('id', [...new Set(idList)].join())
});

export const optionsDniAuthParamOrderCode = (dni, token, orderCode) => ({
    headers: HEADER_DNI_AUTH(dni, token),
    params: new HttpParams().append('code', orderCode)
});