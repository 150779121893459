'use strict';
import { Component, OnInit } from '@angular/core';
import { Navigation, Router } from '@angular/router';
import { FormatAmountPipe } from 'src/app/core/pipes/format-amount.pipe';
import { TagManagerService } from 'src/app/services/tag-manager.service';
import { OrderPayment } from '../../core/interfaces/order.payment.interface';

@Component({
    selector: 'app-servicios',
    templateUrl: './servicios.component.html',
    styleUrls: ['./servicios.component.scss'],
    providers: [FormatAmountPipe]
})
export class ServiciosComponent implements OnInit {

    public clientOrderList: OrderPayment[] = [];

    constructor(
        private router: Router,
        private tag: TagManagerService
    ) {
        this.loadIncommingPayments(this.router.getCurrentNavigation());
    }

    ngOnInit() {
        if (this.clientOrderList.length === 0) {
            this.router.navigate(['pagar']);
        }
    }

    loadIncommingPayments({ extras }: Navigation) {
        const payments: OrderPayment[] = extras && extras.state && extras.state.payments || [];
        this.clientOrderList = payments;

        if (payments && payments.length > 0 && payments[0]) {
            const dniClient = payments[0].clientDNI;
            this.tag.services(dniClient);
        }
    }
}
