import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FormatDniPipe } from '../pipes/format-dni.pipe';

@Directive({
  selector: 'input[appDni]',
  providers: [FormatDniPipe]
})
export class DniDirective {

  constructor(
    private ctrl: NgControl,
    private dniPipe: FormatDniPipe
  ) { }

  @HostListener('input', ['$event'])
  onKeyUp(event: Event) {
    const dniOriginal = this.ctrl.value;
    const dniFormat = this.dniPipe.transform(dniOriginal);
    if (dniOriginal !== dniFormat) {
      this.ctrl.control.setValue(dniFormat);
      event.stopPropagation();
    }
  }

}
