import { Pipe, PipeTransform } from '@angular/core';
import { CURRENCIES } from '../constants/app.constants';
import { CurrencyPipe } from '@angular/common';

@Pipe({
    name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {


    constructor(private currencyPipe: CurrencyPipe) {
    }

    transform(value: string | number, currency: string): string {
        switch (currency) {
            case CURRENCIES.CLP:
                return value && this.currencyPipe.transform(value, currency);
            case CURRENCIES.UF:
                value = value && value.toString().replace(',', '.');
                return value && this.currencyPipe.transform(value, currency, '', '0.0-3') + ' UF';
        }
        return value.toString();
    }

}
