'use strict';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guard/auth.guard';

import { ExitoConfirmacionComponent } from './pages/exito-confirmacion/exito-confirmacion.component';
import { PagoServiciosComponent } from './pages/pago-servicios/pago-servicios.component';
import { ResumenOrdenComponent } from './pages/resumen-orden/resumen-orden.component';
import { ServiciosComponent } from './pages/servicios/servicios.component';

const routes: Routes = [
  { path: 'pagar', component: PagoServiciosComponent },
  { path: 'resumen/:id', component: ResumenOrdenComponent, canActivate: [AuthGuard] },
  { path: 'resumen', component: ResumenOrdenComponent, canActivate: [AuthGuard] },
  { path: 'confirmacion/:orden', component: ExitoConfirmacionComponent, canActivate: [AuthGuard]},
  { path: 'servicios', component: ServiciosComponent, canActivate: [AuthGuard] },
  { path: '', redirectTo: 'pagar', pathMatch: 'full' },
  { path: '**', redirectTo: 'pagar', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
