import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPhone'
})
export class FormatPhonePipe implements PipeTransform {

  transform(value: string, prefix: boolean): string {
    const formattedPhone = value.startsWith('+56') ? value.substring(3) : value;
    return prefix ? '+56' + formattedPhone : formattedPhone;
  }

}
