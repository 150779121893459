'use strict';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Navigation, Router } from '@angular/router';
import { PaymentService } from '../../services/apis/payment.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormatAmountPipe } from 'src/app/core/pipes/format-amount.pipe';
import { whiteSpaceValidator } from 'src/app/core/validators/whitespace.validator';
import { Client } from '../../core/interfaces/client.interface';
import { OrderService } from '../../services/apis/order.service';
import { dniValidator } from 'src/app/core/validators/dni.validator';
import { FormatDniPipe } from 'src/app/core/pipes/format-dni.pipe';
import { FormatPhonePipe } from 'src/app/core/pipes/format-phone.pipe';
import { TagManagerService } from 'src/app/services/tag-manager.service';
import { OrderPayment } from '../../core/interfaces/order.payment.interface';
import { SessionService } from '@services/apis/session.service';
import { savePaymentSession } from '../../utils/localstorage.util';
import { CustomCurrencyPipe } from '../../core/pipes/custom-currency.pipe';

@Component({
    selector: 'app-resumen-orden',
    templateUrl: './resumen-orden.component.html',
    styleUrls: ['./resumen-orden.component.scss'],
    providers: [FormatAmountPipe, FormatDniPipe, FormatPhonePipe]
})
export class ResumenOrdenComponent implements OnInit {


    public formOrder: FormGroup;
    public spinner = false;
    public validForm: boolean;
    private orderCode: string;
    public isErrorFromWebpay = false;
    public stateExtra: Navigation;
    public order: OrderPayment;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private orderService: OrderService,
                private paymentService: PaymentService,
                private toastr: ToastrService,
                private fb: FormBuilder,
                private dniPipe: FormatDniPipe,
                private tag: TagManagerService,
                private phonePipe: FormatPhonePipe,
                private sessionService: SessionService) {
        this.stateExtra = this.router.getCurrentNavigation();
    }

    ngOnInit() {
        this.buildForm();
        this.loadOrderByNavigation(this.stateExtra);
        this.validForm = true;
    }

    buildForm() {
        this.formOrder = this.fb.group({
            txtRut: [{ value: '', disabled: true }, [Validators.required, dniValidator]],
            businessName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50), whiteSpaceValidator]],
            nameUser: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(50), whiteSpaceValidator])],
            emailUser: ['', Validators.compose([Validators.required, Validators.pattern(/^[A-Z0-9._%+-]+@{1}[A-Z0-9.-]+\.[A-Z]{2,3}$/i), whiteSpaceValidator])],
            phoneClient: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(12), whiteSpaceValidator])],
        });
    }

    updateForm({
                   clientDNI,
                   clientBusinessName,
                   clientName,
                   clientEmail,
                   clientPhone,
                   productName,
                   orderAmountAfterTax
               }: OrderPayment) {
        this.formOrder.patchValue({
            txtRut: clientDNI && this.dniPipe.transform(clientDNI),
            businessName: clientBusinessName,
            nameUser: clientName || clientBusinessName,
            emailUser: clientEmail,
            phoneClient: clientPhone && this.phonePipe.transform(clientPhone, false)
        });
        this.tag.resumen(this.isErrorFromWebpay, clientDNI, productName, orderAmountAfterTax);
    }

    payService(): void {

        if (this.formOrder.invalid) {
            this.toastr.error('Debe ingresar todos los campos');
            this.validForm = false;
            return;
        }

        const valueForm = this.formOrder.getRawValue();
        this.spinner = true;
        const client: Client = {
            dni: valueForm.txtRut.replace(/[^Kk0-9]+/g, '').toUpperCase(),
            businessName: valueForm.businessName.trim(),
            phone: this.phonePipe.transform(valueForm.phoneClient.trim(), true),
            email: valueForm.emailUser.trim(),
            name: valueForm.nameUser.trim()
        };

        this.paymentService.executeWebpayPayment(client, this.orderCode).subscribe((response: any) => {
            this.spinner = false;
            if (response.status === 200) {
                savePaymentSession(this.sessionService.token, this.sessionService.dni);
                window.location.href = response.body.data.navigate;
            }
        }, (httpErrorResponse) => {
            if (httpErrorResponse.error && httpErrorResponse.error.error) {
                const { key, message } = httpErrorResponse.error.error;
                this.toastr.error(`${ key } : ${ message }`);
            } else {
                this.toastr.error('Reintente de nuevo más tarde');
            }
            this.spinner = false;
        });
    }

    private loadOrderByNavigation(currentNavigation: Navigation) {
        const previousNavigationData = currentNavigation.extras.state;
        const queryParams = currentNavigation.finalUrl.queryParams;
        const pathParamId = this.activatedRoute.snapshot.paramMap.get('id');

        if (!previousNavigationData && !pathParamId && JSON.stringify(queryParams) === '{}') {
            this.router.navigate(['pagar']);
        }

        this.order = previousNavigationData && (previousNavigationData.order || (previousNavigationData.payments &&
            previousNavigationData.payments[0]));
        this.isErrorFromWebpay = previousNavigationData && previousNavigationData.error;

        this.orderCode = (queryParams && queryParams.id) || pathParamId;

        if (this.order) {
            this.updateForm(this.order);
        } else if (this.orderCode) {
            this.loadOrderByCode(this.orderCode);
        }
    }

    private loadOrderByCode(orderCode: string) {
        this.orderService.getOrderByCode(orderCode)
            .subscribe(response => {
                const order: OrderPayment = response.data && response.data[0] || null;
                if (order) {
                    this.order = order;
                    this.updateForm(order);
                } else {
                    this.router.navigate(['/pagar']);
                }
            }, error => {
                console.error(error);
            });
    }
}
