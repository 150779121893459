import { NgModule } from '@angular/core';
import {
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatOptionModule,
    MatSelectModule,
    MatRadioModule,
    MatSliderModule,
    MatAutocompleteModule,
    MatIconModule
 } from '@angular/material';

@NgModule({
    exports: [
        MatInputModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatOptionModule,
        MatSelectModule,
        MatRadioModule,
        MatSliderModule,
        MatAutocompleteModule,
        MatIconModule
    ]
})
export class MaterialModule {}
