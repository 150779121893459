import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDni'
})
export class FormatDniPipe implements PipeTransform {

  transform(value: string): string {
    const searchPattern = /^(\d{1,2})(\d{3})(\d{3})(\w{1})$/;
    const replacePattern = '$1.$2.$3-$4';
    return value.replace(/[.-]/g, '').replace(searchPattern, replacePattern);
  }

}
