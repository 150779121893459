'use strict';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { path } from 'src/environments/path';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators/map';
import {
    optionsAccessCode,
    optionsDniAuthParamOrderCode,
    optionsDniAuthResponse,
    optionsParamIdList
} from '../../utils/http.utils';
import { PAYABLE_ORDER_STATUS } from '../../core/constants/app.constants';
import { switchMap } from 'rxjs/operators';
import { SessionService } from '@services/apis/session.service';

@Injectable({
    providedIn: 'root'
})
export class OrderService {

    private ApiUrl: string = environment.url_service;
    private ENDPOINT_ORDER_PAYMENT: string = this.ApiUrl + path.ENDPOINT_ORDER_PAYMENT;
    private ENDPOINT_PRODUCT_IMAGES: string = this.ApiUrl + path.ENDPOINT_CLOUD_PRODUCT_IMAGES;

    constructor(private httpClient: HttpClient,
                private sessionService: SessionService) {
    }

    public getOrderByCode(orderCode: string): Observable<any> {
        const options = optionsDniAuthParamOrderCode(this.sessionService.dni, this.sessionService.token, orderCode);
        return this.httpClient
            .get(this.ENDPOINT_ORDER_PAYMENT, options)
            .pipe(map((response: any) => {
                return { data: response.data && response.data.filter(order => PAYABLE_ORDER_STATUS.includes(order.status)) };
            }))
            .pipe(switchMap(({ data, token }: any) => {
                    const idList = data.map(order => order.idProduct);
                    return this.httpClient
                        .get(this.ENDPOINT_PRODUCT_IMAGES, optionsParamIdList(idList))
                        .pipe(map(this.mergeOrderWithImage(data, token)));
                })
            );
    }

    public getPayableOrders(dni: string, googleToken: string, clientAccessCode: string): Observable<any> {
        const urlOrderPayment = `${ this.ENDPOINT_ORDER_PAYMENT }/${ dni }/${ googleToken }`;
        return this.httpClient
            .get(urlOrderPayment, optionsAccessCode(clientAccessCode))
            .pipe(this.filterPayableItems())
            .pipe(switchMap(({ data, token }: any) => {
                    const idList = data.map(order => order.idProduct);
                    return this.httpClient
                        .get(this.ENDPOINT_PRODUCT_IMAGES, optionsParamIdList(idList))
                        .pipe(map(this.mergeOrderWithImage(data, token)));
                })
            );
    }

    private mergeOrderWithImage(data, token) {
        return ({ data: imageList }: any) => ({
            data: data.map(o => ({ ...o, image: imageList.filter(i => i.id === o.idProduct)[0] })),
            token
        });
    }

    private filterPayableItems() {
        return map((response: any) => ({
            data: response.data && response.data.data.filter(order => PAYABLE_ORDER_STATUS.includes(order.status)),
            token: response.data.token
        }));
    }
}
