'use strict';

/* Modules Libraries */
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

/* Modules Projects */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';

/* Modules ngBootstrap */
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

/* Environment */
import { environment } from '../environments/environment';

/* Components */
import { ResumenOrdenComponent } from './pages/resumen-orden/resumen-orden.component';
import { PagoServiciosComponent } from './pages/pago-servicios/pago-servicios.component';
import { ExitoConfirmacionComponent } from './pages/exito-confirmacion/exito-confirmacion.component';
import { ServiciosComponent } from './pages/servicios/servicios.component';
import { ComponentsModule } from './components/components.module';
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import localeEsCl from '@angular/common/locales/es-CL';

registerLocaleData(localeEsCl, 'es-CL');

@NgModule({
  declarations: [
    AppComponent,
    ResumenOrdenComponent,
    PagoServiciosComponent,
    ExitoConfirmacionComponent,
    ServiciosComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RecaptchaV3Module,
    NgbModule,
    MaterialModule,
    SharedModule,
    CoreModule,
    ComponentsModule,
    ToastrModule.forRoot({
      enableHtml: true,
      timeOut: 5000,
      progressBar: true,
      progressAnimation: 'decreasing'
    })
  ],
  providers: [
    CurrencyPipe,
    { provide: LOCALE_ID, useValue: 'es-CL' },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: `${ environment.keyCaptcha }` },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }






