import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[appNumbersOnly]'
})
export class NumbersOnlyDirective {

  private numberKeys: Array<string> = [ '0', '1', '2', '3', '4', '5', '6', '7', '8', '9' ];
  private specialKeys: Array<string> = [ 'Backspace', 'ArrowLeft', 'ArrowRight'];

  constructor() { }

  @Input() appNumbersOnly: boolean;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = event as KeyboardEvent;
    // const value: string = event.target.value;

    if (this.appNumbersOnly) {
      if (this.specialKeys.indexOf(e.key) !== -1) {
        return;
      }

      if (this.numberKeys.indexOf(e.key) !== -1) {
        if (e.key.match(/\d/)) {
          return;
        }
        e.preventDefault();
      } else {
        e.preventDefault();
      }
    }
  }
}
