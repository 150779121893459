import { Injectable } from '@angular/core';
import { sha256 } from 'js-sha256';
import { WebpayBoucher } from '../core/interfaces/webpay.boucher.interface';

@Injectable({
  providedIn: 'root'
})
export class TagManagerService {

  public message = {
    dniInvalid: 'Rut incorrecto',
    clientNotRegister: 'Este rut no cuenta con un cobro asociado.'
  };

  constructor() {
  }

  init(): void {
    (window as any).dataLayer.push({
      event: 'pagina-virtual',
      page: '/portal-pago/inicio',
      pagename: 'portal de pago - inicio',
    });
  }

  error(message: string) {
    (window as any).dataLayer.push({
      event: 'evento-interactivo',
      'evento-interactivo-categoria': 'Portal de Pago - Inicio',
      'evento-interactivo-accion': 'Error',
      'evento-interactivo-etiqueta': `${ message }`,
    });
  }

  resumen(pago: boolean, rut: string, productName: string, clp: number) {
    if (!pago) {
      (window as any).dataLayer.push({
        event: 'pagina-virtual',
        page: '/portal-pago/resumen-orden',
        pagename: 'portal de pago - resumen de orden',
        userID: `${ sha256(rut.toUpperCase()) }`
      });
      (window as any).dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: { step: 1 },
            products: [{
              product: 'integración transbank',
              variant: `${ productName }`,
              category: 'internet',
              price: `${ clp }`,
            }]
          }
        }
      });
    } else { // Rechazo de pago en webpay
      (window as any).dataLayer.push({
        event: 'pagina-virtual',
        page: '/portal-pago/error',
        pagename: 'portal de pago - error',
        userID: `${ sha256(rut.toUpperCase()) }`
      });
    }
  }

  services(rut: string): void {
    (window as any).dataLayer.push({
      event: 'pagina-virtual',
      page: '/portal-pago/servicios-por-pagar',
      pagename: 'portal de pago - servicios por pagar',
      userID: `${ sha256(rut.toUpperCase()) }`
    });
  }

  success(webpayBoucher: WebpayBoucher): void {
    const rut = webpayBoucher.orderClientDni;
    const order = webpayBoucher.webpayResponse.buy_order;
    const amountClp = webpayBoucher.webpayResponse.details[0].amount;
    const productName = webpayBoucher.productName;
    (window as any).dataLayer.push({
      event: 'pagina-virtual',
      page: '/portal-pago/pago-exitoso',
      pagename: 'portal de pago - pago exitoso',
      userID: `${ sha256(rut.toUpperCase()) }`
    });

    (window as any).dataLayer.push({
      event: 'checkout',
      ecommerce: {
        purchase: {
          actionField: {
            id: `${ order }`,
            revenue: `${ amountClp }`
          },
          products: [{
            product: 'integración transbank',
            variant: `${ productName }`,
            category: 'internet',
            price: `${ amountClp }`,
            quantity: '1'
          }]
        }
      }
    });
  }
}
