import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-steps-wix',
  templateUrl: './steps-wix.component.html',
  styleUrls: ['./steps-wix.component.scss']
})
export class StepsWixComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
