import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-container-principal',
  templateUrl: './container-principal.component.html',
  styleUrls: ['./container-principal.component.scss']
})
export class ContainerPrincipalComponent implements OnInit {

  @Input() title = 'Title Default';

  constructor() { }

  ngOnInit() {
  }

}
